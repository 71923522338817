import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"
import Img from "gatsby-image"
import showdown from 'showdown'

const ConsultantPage = ({data}) => {
    let directors = data.graphAPI.directors
    return (
        <Layout>
            <SEO title="Consultants and Vendors" />
            <div className="title-section">
                <h1 className="title is-size-1">Consultants and Vendors</h1>
            </div>
            <div className="content-align consultant-grid" style={{marginBottom: '3rem'}}>
            {data.graphAPI.consultants.map(consultant => (
                <div className="item" key={consultant.id}>
                    <span>
                    <small style={{textTransform: 'uppercase'}}>{consultant.role}</small>
                    <p className="is-size-5">{consultant.company}</p>
                    </span>
                    <p>{consultant.addressLine1}<br />{consultant.addressLine2}</p>
                    { consultant.website ? (<a href={consultant.website} target="_blank" rel="noopener noreferrer">Website</a>) : (<></>)}
                </div>
            ))}
            </div>
        </Layout>
    )
}

export default ConsultantPage

export const query = graphql`
    query consultantPageById {
        graphAPI {
            consultants(sort: "role:asc") {
                id
                role
                company
                addressLine1
                addressLine2
                website
            }
        } 
    }
`


